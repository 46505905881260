
<div class="form-title mt-3 mb-5">
    Other Files
    <div class="mt-3" style="text-align:left;font-size:14px;margin:0 50px">
      <span style="display:inline-block;width:20px"></span>All other files and supporting documents that you would like to submit.  i.e. travel documents, train tickets, certificates, book covers, etc.
      <br><br>
      <span style="text-decoration:underline">File Naming Convention:</span>
      <br><br>
      <b>Other Documents:</b> Firstname_Middlename_Lastname_mm-dd-yyyy_EXTRA_#of#
      <br>
      <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_EXTRA
    </div>
</div>


<app-file-uploader *ngIf="!isUnsubmitted && formStatus != 'Submitted/Received'" [fileType]="fileType" [interviewID]="interviewID"></app-file-uploader>


<div *ngIf="userType !== 'interviewer'" class="form-group form-data" style="margin-top:20px">
  <label for="originalMaterialsFormat">Original other materials gathered format</label>
  <input type="text" class="form-input" id="originalMaterialsFormat">
</div>

<div class="mt-5 text-center">

    <div *ngIf="isUnsubmitted && formStatus === 'Submitted/Received'" style="text-align:center">
      <button *ngIf="userType !== 'archivist'" class="save-button" style="width:190px;outline:none;margin-right:30px" (click)="submitagain()">Update Interview</button>
    </div>

    <button *ngIf="userType !== 'archivist' && formStatus != 'Submitted/Received'" class="save-button" style="width:190px;outline:none;margin-right:30px" (click)="submit()">Submit Interview</button>

    <button  *ngIf="userType === 'admin' && formStatus === 'Submitted/Received' && !isUnsubmitted" class="save-button" style="width:190px;outline:none;margin-right:30px" (click)="unsubmit()">Unsubmit Interview</button>

    <button *ngIf="userType !== 'interviewer'" class="save-button" style="width:190px;outline:none" (click)="archive()">Archive Interview</button>
    <div *ngIf="isSubmitting" style="text-align:center">
      <img src="https://miro.medium.com/max/441/1*9EBHIOzhE1XfMYoKz1JcsQ.gif" style="height:50px">
    </div>
</div>