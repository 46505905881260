import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';


@Component({
  selector: 'app-other-files',
  templateUrl: './other-files.component.html',
  styleUrls: ['./other-files.component.scss'],
})
export class OtherFilesComponent implements OnInit {
  @Input() interviewID: string;

  public fileType = 'other';
  public isSubmitting = false;
  public isUnsubmitted = 0;
  public formStatus = '';

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
  ) { }

  get userType() {
    return this.authService.getUserType();
  }

  ngOnInit() {
    this.backendService.unsubmitStatus(this.interviewID).subscribe(data => {
      this.isUnsubmitted =  Number(data.unsubmit);
      this.formStatus =  data.statusSelect;
    });
  }

  submitagain() {
    this.isSubmitting = true;
    this.backendService.submit(this.interviewID).subscribe(data => {
      this.isSubmitting = false;
      if (data && data.error) {
        this.toastr.error(data.error);
      } else {
        this.toastr.success('Interview submitted successfully');
      }
    });

    this.backendService.resubmit(this.interviewID).subscribe(data => {
      this.isSubmitting = false;
    });

  }

  submit() {
    this.isSubmitting = true;
    this.backendService.submit(this.interviewID).subscribe(data => {
      this.isSubmitting = false;
      if (data && data.error) {
        this.toastr.error(data.error);
      } else {
        this.toastr.success('Interview submitted successfully');
      }
    });
  }

  unsubmit() {
    this.isSubmitting = true;
    this.backendService.unsubmit(this.interviewID).subscribe(data => {
      this.isSubmitting = false;
      if (data && data.error) {
        this.toastr.error(data.error);
      } else {
        this.toastr.success('Interview unsubmitted successfully');
      }
    });
  }


  archive() {
    this.isSubmitting = true;
    this.backendService.archive(this.interviewID).subscribe(data => {
      this.isSubmitting = false;
      if (data && data.error) {
        this.toastr.error(data.error);
      } else {
        this.toastr.success('Interview archived successfully');
      }
    });
  }
}
